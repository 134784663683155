import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import Team from '@mangoart/gatsby-ui/components/ezagrar/Vario-Fendt-Schaeffer/Team';
import DefaultLayout from '../layout/DefaultLayout';

const TeamPage = ({ data, location }) => {
  const { pageData, siteData, employees, departments } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>{content.title}</PageTitle>
      <Team employees={employees} departments={departments} showNavigation={false} exclude={'lamborghini-traktor.at'} />
    </DefaultLayout>
  );
};

export default TeamPage;

export const TeamPageQuery = graphql`
  query TeamPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageTeam {
      content {
        title
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    departments: allCockpitAbteilung {
      nodes {
        id
        Bezeichnung
        slug
      }
    }
    employees: allCockpitMitarbeiter {
      edges {
        node {
          id
          active
          Name
          company
          departments {
            id
            Bezeichnung
          }
          Aufgabenbereiche
          Email
          Telefon
          Mobiltelefon
          Fax
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 200, height: 250, transformOptions: { cropFocus: NORTH })
            }
          }
        }
      }
    }
  }
`;
